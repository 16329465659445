<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
        <el-form-item prop="oldpassword" label="旧密码">
                <el-input v-model="ruleForm.oldpassword" show-password></el-input>
        </el-form-item>
        <el-form-item prop="password" label="新密码">
                <el-input v-model="ruleForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item prop="cpassword" label="确认密码">
                <el-input v-model="ruleForm.cpassword" show-password></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import { modPwd,modPwdAgo } from '@/service/account';
    export default {
        props: {

        },
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入旧密码'));
                } else {
                    // modPwdAgo(this.ruleForm.oldpassword).then((rst)=>{
                    //     callback();
                    // }).catch(err=>{
                    //     // console.log(err);
                    //     if (err.message == '旧密码输入错误') {
                    //         return callback(new Error('旧密码输入错误，请重新输入'));
                    //     }
                    // });
                    callback();
                }
            };
            return {
                ruleForm: {
                    oldpassword: '',
                    password: '',
                    cpassword: ''
                },
                rules: {
                    oldpassword: [{validator: checkPhone, trigger: 'blur'}],
                    password: [
                        { required: true, message: '请输入新密码', trigger: 'blur' }
                    ],
                    cpassword: [
                        { required: true, message: '请输入重复新密码', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        modPwd(this.ruleForm.oldpassword,this.ruleForm.password,this.ruleForm.cpassword).then(()=>{
                            this.$emit("success");
                            this.$message.success("密码修改成功");
                        }).catch(err=>{
                            this.$message.error(err.message);
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    }
</script>
<style scoped>

</style>