import store from '@/store';
import _ from 'lodash'
// import { factoryMenus } from '@/views/factory/menus'
import { purviewMenus } from '@/views/purview/menus'
// import { saleMenus } from '@/views/sale/menus'
// import { mesMenus } from '@/views/mes/menus'
const navs = [
    {
        name: '工作台', key: '/center', menus: [
            ...purviewMenus,
        ]
    },
    // {
    //     name: '门户管理', key: '/portal',
    // },
];
// function _filter(item) {
//     let curCompType = store.state.loginUser.comp.comp_type;
//     let privids = _.get(store.state,'loginUser.user.privids',[]);
//     let pass = true;
//     if (item.compTypes && item.compTypes.indexOf(curCompType) < 0) pass = false;
//     if (item.privi && privids.indexOf(item.privi) < 0) pass = false;
//     return pass;
// }
// function getNavs() {
//     let curUser = store.state.loginUser;
//     if (!(curUser && curUser.comp && curUser.user)) return [];
//     return navs.reduce((pre, curNav) => {
//         if (_filter(curNav)) {
//             let _curNav = _.cloneDeep(curNav);
//             if (_curNav.menus) _curNav.menus = _curNav.menus.reduce((pre1, curMenu) => {
//                 if (_filter(curMenu)) {
//                     if (curMenu.children) curMenu.children = curMenu.children.reduce((pre2, curItem) => {
//                         if (_filter(curItem)) {
//                             pre2.push(curItem);
//                         }
//                         return pre2;
//                     }, []);
//                     if(!curMenu.children || (curMenu.children && curMenu.children.length !=0)){
//                         pre1.push(curMenu);
//                     }
//                 }
//                 return pre1;
//             }, []);
//             pre.push(_curNav);
//         }
//         console.log(pre);
//         return pre;
//     }, []);

// }
export { navs };