export const purviewMenus = [
    // {
    //     title: '权限配置', key: 'purview_set',icon:'icon iconfont icon-hetongguanli-', children: [
    //         { title: '权限代码分类列表', key: '/purview/qxClassSet',icon:'icon iconfont icon-OvalPathmengban' },
    //         { title: '权限代码列表', key: '/purview/qxSet',icon:'icon iconfont icon-OvalPathmengban' },
    //     ]
    // },
    // {
    //     title: '权限管理', key: 'purview',icon:'icon iconfont icon-design', children: [
    //         { title: '角色管理', key: '/purview/role',icon:'icon iconfont icon-eee2' },
    //         { title: '管理员管理', key: '/purview/manager',icon:'icon iconfont icon-OvalPathmengban' },
    //     ]
    // },
    // {
    //     title: '菜单管理', key: 'navs',icon:'icon iconfont icon-hetongguanli-', children: [
    //         { title: '导航菜单管理', key: '/purview/navs',icon:'icon iconfont icon-OvalPathmengban' },
    //     ]
    // },
    // {
    //     title: '注册企业管理', key: 'manager_zc',icon:'icon iconfont icon-hetongguanli-', children: [
    //         { title: '注册企业管理', key: '/purview/zcqy',icon:'icon iconfont icon-icon-test2' }
    //     ]
    // },
    // {
    //     title: '客户管理', key: 'manager_kh',icon:'icon iconfont icon-hetongguanli-', children: [
    //         { title: '客户管理', key: '/purview/khgl',icon:'icon iconfont icon-icon-test2' }
    //     ]
    // },
    // {
    //     title: '运营审批', key: 'manager_yy',icon:'icon iconfont icon-hetongguanli-', children: [
    //         { title: '运营人工审批', key: '/purview/rgsp',icon:'icon iconfont icon-icon-test2' },
    //         { title: '运营后台自动审批', key: '/purview/zdsp',icon:'icon iconfont icon-icon-test2' },
    //     ]
    // },
];