<template>
  <a-layout class="layout">
    <!-- 用户登录后的头部 -->
    <template v-if="logedUser && logedUser.user">
      <a-layout-header class="header flex flex-align-center flex-nowarp">
        <div class="logo">
          <img src="../../assets/images/logo-new.png" />
          <span>南康产业智联网管理后台</span>
        </div>
        <div class="header-right">
          <div class="hr-menu">
            <a-menu
              v-if="navs && navs.length > 0 && defaultSelectedNavKeys"
              theme="dark"
              mode="horizontal"
              :selectedKeys="defaultSelectedNavKeys"
              class="nav-func-menu"
            >
              <a-menu-item v-for="nav in navs" :key="nav.key" @click="clickNav(nav)">{{ nav.name }}</a-menu-item>
            </a-menu>
          </div>
          <div class="hr-head flex">
            <a-menu mode="horizontal" theme="dark" class="nav-user-menu">
              <template v-if="logedUser && logedUser.user">
                <a-sub-menu key="sub1">
                  <span slot="title">
                    <div class="header-img">
                      <img
                        :src="logedUser.user.avatar ? (imgUrl+logedUser.user.avatar):require('../../assets/images/user.png')"
                      />
                    </div>
                    <span>{{logedUser.user.name||'未设置'}}</span>
                  </span>
                  <a-menu-item key="1-1" @click="openModal('个人设置')">个人设置</a-menu-item>
                  <!-- <a-menu-item key="1-2" @click="openModal('修改手机')">修改手机</a-menu-item> -->
                  <a-menu-item key="1-3" @click="openModal('修改密码')">修改密码</a-menu-item>
                  <a-menu-divider />
                  <a-menu-item key="1-4" @click="logout">退出登陆</a-menu-item>
                </a-sub-menu>
              </template>
              <template v-else>
                <a-menu-item key="2" @click="openModal('用户登录')">登录</a-menu-item>
                <!-- <a-menu-item key="3" @click="goto('/register')">注册</a-menu-item> -->
              </template>
            </a-menu>
          </div>
        </div>
      </a-layout-header>
    </template>
    <!-- 用户未登录的头部 -->
    <!-- <template v-else>
            <div class="header flex flex-align-center flex-nowarp new-header">
                <div class="logo" @click="goto('/home')">
                    <img src="../../assets/images/home/logo.png">
                </div>
                <div class="flex-1 flex menu-bar">
                    <div class="btn">首页</div><div class="btn">云平台</div><div class="btn">解决方案</div><div class="btn">接入指南</div><div class="btn">关于我们</div>
                </div>
                <div class="flex log-bar" style="margin-right:auto;padding-right: 80px;">
                    <div class="btn" style="margin-right:30px;" @click="openModal('用户登录')">登录</div>
                </div>
            </div>
    </template>-->
    <a-layout class="layout-page" :class="{ nopadding: !(logedUser && logedUser.user) }">
      <!-- 边栏 -->
      <a-layout-sider
        v-if="menus && menus.length>0 && $route.path != '/portal'"
        width="240"
        class="layout-sider"
        collapsible
        v-model="siderCollapsed"
      >
        <a-menu
          :selectedKeys="[$route.path]"
          :openKeys.sync="leftMenu_defaultOpenKeys"
          style="margin-bottom: 48px"
          mode="inline"
          theme="dark"
          @click="clickItem"
        >
          <template v-for="item in menus">
            <a-menu-item v-if="!item.children" :key="item.key">
              <span :class="[item.icon ? item.icon:'icon iconfont icon-yanjing']"></span>
              <span>{{item.title}}</span>
            </a-menu-item>
            <sub-menu :menu-info="item" :key="item.key" />
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 0;">
        <a-layout-content class="layout-content" style="height: 100%">
          <router-view />
          <!-- <iframe src="menuSrc"></iframe> -->
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- 弹出框 -->
    <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
      <Config v-if="popTitle=='个人设置'" @success="popVisible=false"></Config>
      <ModPwd v-if="popTitle=='修改密码'" @success="popVisible=false"></ModPwd>
    </el-dialog>
  </a-layout>
</template>
<script>
import SubMenu from "./SubMenu";
import { navs } from "./menu";
import Config from "@/components/user/Config.vue";
import ModPwd from "@/components/user/ModPwd.vue";
import { logOut, navBuildNav } from "@/service/account";
import localForage from "localforage";
import { IMG_URL_PRE } from "@/config";
export default {
  components: {
    "sub-menu": SubMenu,
    Config,
    ModPwd
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: null,
      menus: null,
      leftMenus: [],
      siderCollapsed: false,
      headerUserNavCollapsed: false,
      defaultSelectedNavKeys: [],
      leftMenu_defaultOpenKeys: [],
      matchItems:[],
    };
  },
  watch: {
    logedUser: function(newVal) {
      if (!newVal) {
        this.menus = null;
      }
    },
    $route: {
      handler: function(val, oldVal) {
        if (this.$route.path != "/home") {
          // this.menus = this.navs;
          // this.menus = this.leftMenus;
          this.leftMenu();
        }
        this.refreshMenu();
      },
      // 深度观察监听
      deep: true
    }
  },
  computed: {
    logedUser() {
      return this.$store.state.loginUser;
    },
    navs() {
      // console.log(navs);
      return navs;
    }
  },
  created: async function() {
    console.log("this.navs",this.navs);
    if (this.$route.path != "/home") {
      this.leftMenu();
    }
    this.refreshMenu();
    // let curNavKey = await localForage.getItem('curNavKey');
    // // console.log(666,curNavKey);
    // this.defaultSelectedNavKeys = [curNavKey];
    // // console.log(1111,this.defaultSelectedNavKeys);
    // let curNav = this.navs.find(r=>{
    //     return r.key==curNavKey;
    // });
    // if(curNav){
    //     this.menus = curNav.menus;
    //     this.autoOpenMenu(curNav);
    // }
  },
  mounted() {},
  methods: {
    goto(path) {
      this.menus = null;
      this.$router.push(path);
    },
    openModal(title) {
      this.popTitle = title;
      this.popVisible = true;
    },
    logout() {
      logOut().then();
    },
    async clickNav(nav) {
      if (nav.key && nav.key.indexOf("/") == 0) {
        this.$router.push(nav.key);
      }
      // this.menus = nav.menus;
      this.refreshMenu();
    },
    clickItem(item) {
      if (item.key && item.key.indexOf("/") == 0) {
        this.$router.push(item.key);
      }
      // if (item.key){
      //     // location.href = item.key;
      //     this.menuSrc = item.key;
      // }
    },
    refreshMenu() {
      this.defaultSelectedNavKeys = [this.$route.path];
    },
    leftMenu() {
      // this.menus = this.navs;
      let leftMenus = [];
      navBuildNav().then(rst => {
        rst.forEach((e, idx) => {
          let nav = {
            title: e.name,
            key: "purview" + e.id,
            icon: "icon iconfont icon-bianzu13",
            children: []
          };
          if (e) {
            leftMenus.push(nav);
            e.children.forEach((n, idx1) => {
              let nav1 = {
                title: n.name,
                key: n.url,
                icon: "icon iconfont icon-bianzu13"
              };
              leftMenus[idx].children.push(nav1);
            });
          }
        });
        this.menus = leftMenus;
        // console.log(rst);
        // console.log(this.menus);
      }).catch(err => {
        this.$message.error(err.message);
      });
    },
    autoOpenMenu(curNav) {
      if (curNav.menus) {
        ok: for (let i = 0; i < curNav.menus.length; i++) {
          let children = curNav.menus[i].children;
          if (children == null) continue;
          for (let j = 0; j < children.length; j++) {
            let children2 = children[j];
            if (children2.children) {
              let children3 = children2.children;
              for (let k = 0; k < children3.length; k++) {
                if (children3[k].key == this.$route.path) {
                  this.leftMenu_defaultOpenKeys = [
                    curNav.menus[i].key,
                    children2.key
                  ];
                  break ok;
                }
              }
            } else {
              if (children2.key == this.$route.path) {
                this.leftMenu_defaultOpenKeys = [curNav.menus[i].key];
                break ok;
              }
            }
          }
        }
      }
    }
  }
};
</script>
<style>
.container {
  width: 1200px;
  margin: 0 auto;
}
.nav-func-menu,
.nav-user-menu {
  background: none;
}

.ant-menu-submenu-popup .iconfont + span {
  margin-left: 10px;
}

.nav-func-menu .ant-menu-item,
.nav-user-menu .ant-menu-item {
  height: 100%;
  line-height: 60px;
}

.nav-func-menu .ant-menu-item-selected {
  background: rgba(249, 249, 249, 0.1) !important;
  /* opacity:0.1; */
  /* color: rgba(213, 160, 93, 1); */
  color: #e6e6e6;
}

.nav-user-menu .ant-menu-item-selected {
  background-color: transparent !important;
}

.nav-user-menu .ant-menu-submenu {
  height: 100%;
  border-bottom: none !important;
}

.nav-user-menu .ant-menu-submenu-title {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-user-menu .ant-menu-submenu-content {
  margin-top: 0;
}
</style>
<style scoped>
.layout {
  height: 100%;
  min-width: 1200px;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 0;
  /* position: fixed; */
  /* 适应手机端左右滑动 */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1200px;
  z-index: 100;
  background: #5074ee;
}
.header-right {
  width: calc(100% - 240px);
  height: 60px;
  padding-left: 120px;
}
.header-right:after {
  clear: both;
}
.header-right > div {
  display: inline-block;
  vertical-align: top;
}
.hr-title {
  line-height: 60px;
  margin-right: 4%;
  margin-left: 3%;
}
.logo {
  width: 305px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  color: rgba(249, 249, 249, 1);
  /* border-right:1px solid rgba(71,101,221,1); */
  background: url("../../assets/images/logo_bg.png") no-repeat center center;
  background-size: 100%;
}
.logo img {
  height: 40px;
  margin-right: 10px;
}
.hr-menu {
  height: 60px;
  line-height: 60px;
}
.hr-head {
  height: 60px;
  line-height: 60px;
  float: right;
  margin-right: 20px;
}
.hr-head .menu_head {
  display: inline-block;
}
.hr-head .ant-menu-horizontal {
  /* width: 200px; */
  height: 60px;
  line-height: 60px;
  padding-left: 8%;
}
.nav-btn {
  padding: 17px 15px;
  color: #fff;
  cursor: pointer;
}

.layout-page {
  padding-top: 60px;
}
.layout-page.nopadding {
  padding-top: 0;
}
.layout-content {
  margin: 0;
  min-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  /* background: url('../../assets/images/reg_bg.jpg') no-repeat center center; */
}

.layout-page >>> .layout-sider.ant-layout-sider {
  background: #414b5b;
}
.layout-page >>> .ant-layout-sider-children {
  overflow-y: auto;
}
.layout-page >>> .ant-menu-dark,
.layout-page >>> .ant-layout-sider-trigger {
  background: #414b5b;
}
.layout-page >>> .ant-layout-sider-trigger {
  /* 适配手机端左右滑动 */
  position: absolute;
}
.layout-page >>> .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #414b5b;
}
/* div>>> ul.ant-menu-dark,div>>> .ant-menu-dark .ant-menu-sub {
            background: #414B5B;
        } */
.layout-sider {
  overflow: auto;
  /* height: 100vh; */
  /* position: fixed; */
  /* left: 0; */
  /* top: 0; */
  /* padding-top: 80px; */
}
.layout-sider >>> .iconfont + span {
  margin-left: 10px;
}
.layout-sider >>> .ant-menu-inline-collapsed .iconfont + span {
  display: none;
}
.layout-sider >>> .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #5074ee;
}
.header-img {
  width: 42px;
  height: 42px;
  position: absolute;
  left: -40%;
  top: 8px;
}

.header-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  vertical-align: top;
}

.layout >>> .el-dialog {
  min-width: 400px;
}

.new-header {
  background: linear-gradient(
    360deg,
    rgba(0, 70, 162, 0.5) 0%,
    rgba(0, 72, 170, 0.5) 100%
  );
  overflow: hidden;
}
.new-header .btn {
  padding: 5px 15px;
  color: #fff;
  cursor: pointer;
  border-radius: 16px;
}
.new-header .btn:hover {
  color: #1dffdc;
}
.new-header .menu-bar .btn {
  margin: 0 15px;
  font-size: 16px;
}
.new-header .menu-bar .btn.active {
  color: #1dffdc;
}
.new-header .log-bar .btn {
  width: 84px;
  height: 32px;
  text-align: center;
}
.new-header .log-bar .btn.bordered {
  border: 1px solid #fff;
}
.nav-user-menu .menu_head i {
  display: inline-block;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  margin-left: 5px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.nav-user-menu:hover .menu_head i {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
}

/*--------------滚动条样式----------------*/
.layout>>> .ant-layout-sider-children::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.layout>>> .ant-layout-sider-children::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  -webkit-border-radius: 6px;
}
.layout>>> .ant-layout-sider-children::-webkit-scrollbar-thumb:vertical {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  -webkit-border-radius: 6px;
}
.layout>>> .ant-layout-sider-children::-webkit-scrollbar-thumb:horizontal {
  width: 3px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  -webkit-border-radius: 6px;
}

@media (max-width: 980px) {
  .hr-title {
    display: none;
  }
}
</style>